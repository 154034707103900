
.App{
  padding-bottom: 20px;
}
.headerSection{
    background-color: white;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 2em;
    margin-right: 2em;
  }
  .headerMenuItemHolder{
    display: flex;
    align-items: flex-end;
    width: 70%;
  }
  .headerMenuItem{
    color: #0091D3;
    text-decoration: none;
    margin-right: 20px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
  }
  .headerMenuIcon{
    margin-right: 20px;
  }
  .headerMenuItemHolder{
    margin: 20px;
  }
  .headerIconHolder{
    display: flex;
    align-items: center;
  }
  .companyHeaderLogo{
    max-height: 25px;
    max-width: 128px;
  }
  .topSection{
    background-color: #0091D3;
    height: 196px;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
  }
  .topSectionUnitName{
    color: white;
    margin-left: 30px;
    margin-bottom: 85px;
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
  .topSectionUnitName sub{
    font-family: Inter;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
  .topSectionMenu{
    position: absolute;
    bottom: 0;
    display: flex;
    width: 100%;
    margin-left: 50px;
  }
  .topSectionMenuItem{
    position: absolute;
    bottom: -5px;
    display: flex;
    background: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 5px;
    padding: 10px;
  }
  .equipmentStatusIndicator{
    display: flex;
    align-items: center;
    margin: 20px;
    max-width: 300px;
  }
  .equipmentStatusIcon{
    margin: 15px
  }
  .equipmentStatusIndicatorTextSectionState{
    margin-top: 5px;
    margin-bottom: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-transform: capitalize;
  }
  .equipmentStatusIndicatorTextSectionText{
    margin-top: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
  }
  .statusIndicatorSection{
    display: flex;
    align-items: center;
    width: 80%;
    justify-content: space-evenly;
  
  }
  .MetaDataSection{
    max-width: 200px;
    margin-top: 20px;
    margin-right: 15px;
    width: 20%;
  }
  .MetaDataSectionData{
    text-align: right;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    margin-bottom: 2px;
    margin-top: 2px;
    color: #666666;
  }
  .MetaDataSectionLastUpdated{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    color: #000000;
  }
  .equipmentStatusHeader{
    display: flex;
    justify-content: space-between;
  }
  .equipmentOperationalHistorySection{
    background-color: #d1d1d1;
  }
  hr{
    border-color: black;
    border-style: solid;
    border-width: 1px;
    max-width: 90%;
  }
  .showMoreButton{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #666666;
    margin-left: 20px;
  }
  .equipmentUseageStatsHolder{
    margin: 36px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .equipmentUseageStatsSince{
    margin: 0px 0px 5px 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .equipmentUseageStatsHeader{
    margin: 30px 0px 5px 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .equipmentUseageStatsHeaderIcon{
    margin: 3px 6px;
    width: 0; 
    height: 0; 
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 16px solid #666666;
  }
  .equipmentUseageStatsHeaderInfoText{
    color: #666666;
    text-decoration: none;
    margin-right: 20px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
  }
  .equipmentStatsBox{
    width: 150px;
    height: 120px;
    background: #FFFFFF;
    border: 1px solid #999999;
    border-radius: 10px;
    display: flex;
    flex-direction:column;
    align-items: center;
    margin: 15px;
    position: relative;
  }
  .equipmentStatsText{
    width: 100%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    position: absolute;
    /* margin-top: 16px !important */
  }
  .equipmentStatsValue{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 42px;
    position:absolute;
    top: 21px;
    margin-top: 35px !important
  }
  .equipmentStatsValueUnit{
    font-size: 15px;
  }
  .equipmentStatsUpdated{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 15px;
    position:absolute;
    bottom: 5px;
    margin-bottom: 0px;
  }
  .equipmentStatsDecorator{
    position: absolute;
    bottom: -25px;
  }
  .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
    left: 60px;
  }
  .tooltip .tooltiptext {
    width: 140px;
    background-color: #0091D3;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px;
    position: absolute;
    z-index: 1;
  }
  
  